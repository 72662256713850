import { FC } from 'react';
import Select from 'react-select';

import { FormFieldProps } from './types';

const FormField: FC<FormFieldProps> = ({
  labelText,
  OptionalText,
  isIcon,
  name,
  type,
  onChange,
  onClick,
  value,
  readonly,
  errors = {},
  touched,
  parentDivClass,
  inputFieldClass,
  placeholder,
  title,
  isSearchable,
  onFocus,
  onBlur,
  maxLength,
  minValue,
  handleLoading = { enable: false, inputValue: '' },
  options,
  className,
  disabled,
  onWheel,
  isRequired,
  inputLabelClass
}) => {
  return (
    <div className={'form-group new_form_design'}>
      {labelText && !OptionalText ? (
        <label className="position-relative light-blue font-plex f-w4 f-s16">
          {labelText}
          <span className="cz-ml-8">{isIcon}</span>
          {isRequired && <span className="form-isRequired">*</span>}
        </label>
      ) : (
        <label className={`d-flex gap-2 ${inputLabelClass}`}>
          {labelText}
          <span className="f-s14 text-transform-none font-plex f-w5 ls-normal">
            {OptionalText}
          </span>
        </label>
      )}
      {type.toLowerCase() === 'select' ||
      type.toLowerCase() === 'multiselect' ? (
        <div className={`form-group`}>
          <Select
            value={value}
            options={options}
            isDisabled={disabled}
            classNamePrefix="react-select"
            className={`${
              errors[name] && touched[name] ? 'input_error' : ''
            } ${inputFieldClass}`}
            placeholder={placeholder}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={isSearchable ? true : false}
            onChange={onChange}
            isMulti={type.toLowerCase() === 'multiselect'}
            maxMenuHeight={180}
          />
        </div>
      ) : (
        <input
          name={name}
          disabled={disabled}
          type={type}
          className={`form-control ${inputFieldClass ? inputFieldClass : ''} ${
            errors[name] && touched[name] ? 'input_error' : ''
          }`}
          onChange={onChange}
          onClick={onClick}
          value={value}
          placeholder={placeholder}
          readOnly={readonly}
          title={title}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          min={minValue}
          onWheel={onWheel}
        />
      )}
      {errors && touched && errors[name] && touched[name] && (
        <span className={'clr-dark-red f-s12 font-plex mt-1 d-inline-block'}>
          {errors[name]}
        </span>
      )}
    </div>
  );
};

export default FormField;
